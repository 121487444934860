<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/relatorio-vendas">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>Vendas no Período</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        v-show="$vuetify.breakpoint.smAndUp"
                        @keypress.enter="consultar"
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />

                    <v-spacer/>

                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                color="primary"
                                :loading="btnExportar"
                            >
                                <v-icon>mdi-chevron-down</v-icon>
                                Exportar
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="gerarPdf">
                                <v-list-item-title>
                                    <v-icon class="mr-2">
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <span>PDF</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="gerarXls">
                                <v-list-item-title>
                                    <v-icon class="mr-2">
                                        mdi-file-excel
                                    </v-icon>
                                    <span>Xls</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-spacer/>

                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="170"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <v-spacer/>
                                Período
                                <v-spacer/>
                            </v-card-title>

                            <v-divider/>

                            <v-card-text>
                                <v-row class="mt-4">
                                    <v-col cols="12" align="center" justify="center">
                                        <v-datetime-picker
                                            label="Data inicial"
                                            v-model="form.start"
                                            dateFormat="dd/MM/yyyy"
                                            timeFormat="HH:mm"
                                            okText="Confirmar"
                                            clearText="Cancelar"
                                            :datePickerProps="{
                                                'locale': 'pt-BR',
                                                'format': '24hr',
                                                'ampm-in-title': true,
                                            }"
                                            :textFieldProps="{
                                                'outlined': true,
                                                'dense': true,
                                                'hide-details': true,
                                            }"
                                        />
                                    </v-col>
                                    <v-col cols="12" align="center" justify="center">
                                        <v-datetime-picker
                                            label="Data final"
                                            v-model="form.end"
                                            dateFormat="dd/MM/yyyy"
                                            timeFormat="HH:mm"
                                            okText="Confirmar"
                                            clearText="Cancelar"
                                            :datePickerProps="{
                                                'locale': 'pt-BR',
                                                'format': '24hr',
                                                'ampm-in-title': true,
                                            }"
                                            :textFieldProps="{
                                                'outlined': true,
                                                'dense': true,
                                                'hide-details': true,
                                            }"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-checkbox
                                            v-model="form.listarCancelados"
                                            label="Listar cancelados"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn color="gray" text @click="menu = false">Cancelar</v-btn>
                                <v-spacer/>
                                <v-btn color="primary" @click="consultar" class="ml-1">
                                    <v-icon>mdi-magnify</v-icon>&nbsp;Consultar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :items="dados"
                            :headers="headers"
                            :loading="loading"
                            :mobileBreakpoint="0"
                            :height="$vuetify.breakpoint.height - 255"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            @click:row="openComanda"
                            :options.sync="options"
                        >
                            <template v-slot:item.id="{item}">
                                <span style="color: blue; text-decoration: underline; cursor: pointer;">
                                    {{ item.id }}
                                </span>
                            </template>
                            <template v-slot:item.cliente_nome="{item}">
                                <span>
                                    {{ item.cliente_id }} - {{ item.cliente_nome }}
                                </span>
                            </template>
                            <template v-slot:item.total="{item}">
                                <span style="color: green;">
                                    R$&nbsp;{{ formatPrice(+item.total) }}
                                </span>
                            </template>
                            <template v-slot:item.valor_frete="{item}">
                                <span v-if="+item.valor_frete" style="color: green;">
                                    R$&nbsp;{{ formatPrice(+item.valor_frete) }}
                                </span>
                            </template>
                            <template v-slot:item.status="{item}">
                                <v-chip
                                    class="white--text"
                                    :color="item.status == 'cancelado' ? 'red' : 'green'"
                                >
                                    {{ item.status }}
                                </v-chip>
                            </template>
                        </v-data-table>
                        <div v-if="pagination.totalizadores">
                            <div style="display: flex; overflow-y: auto;">
                                <div v-for="(pgto, i) in pagination.totalizadores" :key="i" class="my-1 mx-2" style="white-space: nowrap;">
                                    <b>{{ pgto.descricao }}:</b> R$ {{ formatPrice(pgto.total) }}
                                </div>
                            </div>
                        </div>

                        <Paginate
                            v-model="pagination"
                            @input="consultar"
                        />
                    </v-card-text>
                </v-card>

                <DialogComanda
                    v-model="comanda"
                    :visible="dialogComanda"
                    @close="dialogComanda = false"
                />
            </v-content>
            <a ref="aDownload" style="display: none;"></a>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer.vue';
import Paginate from '@/components/Paginate.vue';
import DialogComanda from '@/pages/home/DialogComanda.vue';

export default {
    name: 'VendasDetalhes',

    components: {
        BaseContainer,
        DialogComanda,
        Paginate,
    },

    data: () => ({
        search: '',
        form: {},
        venda: {},
        comanda: {},
        dialogComanda: false,
        loading: false,
        btnExportar: false,
        menu: false,
        dados: [],
        headers: [
            { text: 'Nº', value: 'id' },
            { text: 'Cliente', value: 'cliente_nome' },
            { text: 'Data/Hora', value: 'datahora',  align: 'center' },
            { text: 'R$ Total', value: 'total' },
            { text: 'Forma Pgto', value: 'formas_pagamento' },
            { text: 'Forma Entrega', value: 'forma_entrega' },
            { text: 'Taxa Entrega', value: 'valor_frete' },
            { text: 'Status', value: 'status' },
            // { text: '', value: 'action', sortable: false, align: 'end'},
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    watch: {
        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    mounted() {
        this.venda = this.$store.getters.getData;
        this.$store.commit('setData', {});

        this.form.start = this.moment().startOf(this.venda.periodo).format('DD/MM/YYYY HH:mm'),
        this.form.end = this.moment().endOf(this.venda.periodo).format('DD/MM/YYYY HH:mm');

        this.consultar();
    },

    methods: {
        consultar() {
            this.dados = [];
            this.menu = false;

            const params = this.getParams();

            this.loading = true;
            this.$http.get(`relatorio-vendas/pedidos?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
            }).finally(() => (this.loading = false));
        },

        getParams() {
            const params = new URLSearchParams();

            const start = this.moment(this.form.start, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            const end = this.moment(this.form.end, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');

            params.append('report', '\\App\\Reports\\VendasReport');
            params.append('start', start);
            params.append('end', end);
            params.append('q', this.search || '');
            params.append('listarCancelados', !!this.form.listarCancelados);
            params.append('page', this.pagination.current_page);

            if (this.options.sortBy.length) {
                params.append('sortField', this.options.sortBy[0]);
            }
            if (this.options.sortDesc.length) {
                params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            }

            return params.toString();
        },

        async openComanda(comanda) {
            this.$root.$emit('loading', true);
            this.$http.get(`pedidos/${comanda.id}`)
                .then(resp => {
                    this.comanda = resp.data;
                    this.dialogComanda = true;
                })
                .finally(() => this.$root.$emit('loading', false));
        },

        async gerarPdf() {
            if (this.btnExportar) {
                return;
            }
            this.btnExportar = true;
            try {
                const params = this.getParams();

                const resp = await this.$gerarPdf(params);
                if (resp?.type === 'warning') {
                    this.notify(resp.msg, 'warning');
                }
            } finally {
                this.btnExportar = false;
            }
        },

        async gerarXls() {
            if (this.btnExportar) {
                return;
            }

           if (!this.form.start || !this.form.end) {
                this.notify('Informe o período', 'warning');
                return;
            }

            const start = this.moment(this.form.start, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            const end = this.moment(this.form.end, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');

            const data = {
                report: '\\App\\Reports\\VendasReport',
                start,
                end,
                q: this.search || '',
                listarCancelados: !!this.form.listarCancelados,
            };
            const post = [];

            for (var i in data) {
                post.push(i + '=' + encodeURIComponent(data[i]));
            }
            const link = this.$refs.aDownload;
            this.btnExportar = true;

            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = ({target}) => {
                if (target.readyState === 4 && target.status === 200) {
                    const blob = xhr.response;
                    const url = window.URL || window.webkitURL;

                    link.download = `Relatorio_Vendas_${this.moment().format('DD_MM_YYYY')}.xls`;
                    link.href = url.createObjectURL(blob);
                    link.click();

                    this.btnExportar = false
                }
            };
            xhr.open('POST', `${this.$urlAPI}/report/gerar-xls`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            xhr.setRequestHeader('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
            xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

            xhr.responseType = 'blob';

            xhr.send(post.join('&'));
        },
    }
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}
</style>
