import { render, staticRenderFns } from "./VendasDetalhes.vue?vue&type=template&id=c2069be8&scoped=true&"
import script from "./VendasDetalhes.vue?vue&type=script&lang=js&"
export * from "./VendasDetalhes.vue?vue&type=script&lang=js&"
import style0 from "./VendasDetalhes.vue?vue&type=style&index=0&id=c2069be8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2069be8",
  null
  
)

export default component.exports